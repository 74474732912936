.backgroundfixedphoto{
     
    height:  220px;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-image: url(https://i.postimg.cc/wBsJM61M/13822531-1910-i211-011-underwater-ocean-wave-landscape-realistic.jpg);
    background-attachment: fixed;
    color: rgb(11, 135, 149);
    display: flex;
    justify-content: center;
    align-items: center;

  }

  .colors{
    background-color: rgb(6, 103, 136);
  }

  .packagedrinkingwater{
    background-color: rgb(220, 237, 250);
  }