.menarelwaterberhampore{
    background-color: aliceblue;
}

.btn-grad76 {
    background-image: linear-gradient(to right, #124996 0%, #ab036a  51%, #2b5876  100%);
    margin: 0px;
    padding: 9px 29px;
    font-size: 14px;
    text-align: center;
    /* text-transform: uppercase; */
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0 0 20px #eee;
    border-radius: 18px;
    display: block;
  }
  
  .btn-grad76:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }