.newradi{
    border-radius: 16px;
}

.productsbutton{
    background-color: #071865; /* Green */
  border: none;
  color: rgb(246, 248, 248);
  padding: 7px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  border-radius: 4px;
}

.deskcolor{
    background-color: #ecf5f8;
}

.whatsappbutton{
    background-color: #23a54a; /* Green */
    border: none;
    color: rgb(246, 248, 248);
    padding: 7px 17px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 2px;
  }


  .circleb{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    color: #ffffff;
    background-color: #23a54a;
    border: 1px solid rgb(10, 180, 81);
  }

  .textnewstyle{
    font-family: "Oxanium", serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
  }